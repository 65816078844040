.MuiDataGrid-columnHeaderTitle {
  color: #b5b5c3 !important;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
  font-family: Inter, Helvetica, "sans-serif";
  text-transform: uppercase !important;
}

.MuiDataGrid-columnHeaderTitle:hover {
  color: var(--bs-gray-700) !important;
}

.MuiDataGrid-cellContent,
.MuiDataGrid-cell--withRenderer,
.table-loader-label {
  color: #3f4254 !important;
  font-family: Inter, Helvetica, "sans-serif";
  font-size: 0.9rem !important;
  letter-spacing: 1.1px;
  font-weight: 500 !important;
}

input[type="time"] {
  display: inline-block;
  position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.rmsc .dropdown-container {
  position: relative !important;
  outline: 0 !important;
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
}

input[type="datetime-local"] {
  display: inline-block;
  position: relative;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

#kt_app_header {
  background-color: white !important;
}

.flag-dropdown {
  background-color: #f9f9f9 !important;
  border: none !important;
}

.flag-dropdown:hover {
  background-color: #f9f9f9 !important;
}
